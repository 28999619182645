import { html, css, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';

@customElement('sdb-overlay-element')
class SdbOverlayElement extends LitElement {

    static get styles() {
        return css`
            :host {
                --theme-primary: var(--sdb-theme-primary, #00b1aa);
                --theme-secondary: var(--sdb-theme-secondary, #1e958c);
                --theme-tertiary: var(--sdb-theme-tertiary, #1f9bb4);
                --text-primary: #023850;
                --text-light: #ffffff;
                --text-dark: #023850;
                font-family: Source Sans Pro, sans-serif;
                --font-family: Source Sans Pro, sans-serif;
                --font-default: 400 16px Source Sans Pro, sans-serif;
                --btn-primary: var(--sdb-button-primary, #f49d10);
                --btn-primary-hover: var(--sdb-button-primary-hover, #ed7422);
                --btn-secondary: var(--sdb-button-secondary, #023850);
                --btn-secondary-hover: var(--sdb-button-secondary, #013247);
                --btn-disabled: #eee;
            }
        `;
    }

    render() {
        return html`<slot></slot>`;
    }

    firstUpdated() {
        let container = document.getElementById('app_overlay_container');

        if (!container) {
            container = document.createElement('div', { id: 'app_overlay_container' });
            document.body.appendChild(container);
        }
        container.appendChild(this);
    }
}

