import locales from '../app/locales.js';

export const getLanguage = () => (navigator.language || navigator.browserLanguage || 'en').substring(0, 2);

export const translate = (key, context) => {
    if (!key) { return ''; }

    if (!translations || !translations[key]) { return key; }

    if (!context) { return translations[key]; }

    return interpolate(translations[key], context);
};

const interpolate = (s, ctx) => new Function('return `' + s + '`;').call(ctx);

const translations = locales[getLanguage()] || locales.en;
