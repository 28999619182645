import { LitElement, html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { store } from '../../store/store.js';
import { handleSilentRenewCallback } from '../../store/auth/auth.js'

@customElement('sdb-authenticate-silent-renew')
class SdbAuthenticateSilentRenew extends LitElement {

    render() {
        return html`<div></div>`;
    }

    firstUpdated() {
        store.dispatch(handleSilentRenewCallback());
    }
}
