import { LitElement, html, css, nothing } from 'lit';
import { customElement, property, state, query } from 'lit/decorators.js';
import { choose } from 'lit/directives/choose.js';
import { classMap } from 'lit/directives/class-map.js';
import connect from 'wc-context-redux/connect';
import { userManager } from '../../store/auth/userManager.js';
import { reloadCompositionState } from '../../store/composition/composition.js';
import { closeElearning } from '../../store/elearning/elearning.js';
import { launchTypes } from '../../store/elearning/helpers.js';
import '@sdblearning/sdb-os-player/sdb-os-player.js';
import '@sdblearning/sdb-learning-frontend';
import '@polymer/paper-ripple';
import '../scorm/sdb-scorm-player.js';

const mapStateToProps = state => ({
    opened: state.elearning?.popupOpen === true,
    playerType: state.elearning.playerType,
    launchType: state.elearning.launchType,
    launchData: state.elearning.launchData,
    launchUrl: state.elearning?.popupOpen === true ? state.elearning.launchUrl : 'about:blank',
    autoClose: state.elearning?.popupOpen === true ? !!state.elearning.autoClose : true,
    user: state.auth.user,
    preview: state.env.preview,
});

const mapDispatchToProps = dispatch => ({
    reloadCompositionState: () => dispatch(reloadCompositionState()),
    closeElearning: () => dispatch(closeElearning()),
    closeAndNotifyElearning: () => dispatch(closeElearning({ command: 'notify' }))
});

@customElement('sdb-elearning-dialog')
class SdbElearningDialog extends connect(mapStateToProps, mapDispatchToProps)(LitElement) {

    @property({ type: Boolean })
    accessor transparent;

    @state()
    accessor opened;

    @state()
    accessor playerType;

    @state()
    accessor launchData;

    @state()
    accessor launchUrl;

    @state()
    accessor user;

    @query('#scorm_player')
    accessor scormPlayer;

    constructor() {
        super();

        window.addEventListener('message', msg => {
            switch (msg.data) {
                case 'close-elearning-modal':
                    this.closeElearning();
                    this.reloadCompositionState(); // failsafe call, signalR should have updated it by now
                    break;
                case 'close-elearning-and-notify':
                    this.closeAndNotifyElearning();
                    this.reloadCompositionState(); // failsafe call, signalR should have updated it by now
                    break;
            }
        });
    }

    static get styles() {
        return css`
                paper-dialog {
                    width: 90%;
                    height: 90%;
                }

                paper-dialog.transparent {
                    background: transparent !important;
                    box-shadow: none !important;
                }

                paper-dialog.new-window {
                    background: #fff !important;
                    width: 40%;
                    height: 30%;
                }

                iframe, sdb-os-player, sdb-scorm-player {
                    width: 100%;
                    height: 100%;
                    border: none;
                    margin:0; /* override paper-dialog style */
                    padding:0; /* override paper-dialog style */
                }

                div.close {
                    position: absolute;
                    width: 40px;
                    height: 40px;
                    right: -30px;
                    top: -30px;
                    background: white;
                    border: 1px solid #ededed;
                    border-radius: 50%;
                    padding: 0;
                    margin: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
                }

                div.close[hidden] {
                    display: none;
                }

                iron-icon {
                    --iron-icon-width: 24px;
                    --iron-icon-height: 24px;
                }

                @media only screen and (max-width: 768px) {
                    paper-dialog[autoclose] {
                        width: 100%;
                        min-width: 100%;
                        height: 100%;
                        min-height: 100%;
                    }
                }
        `;
    }

    render() {
        if (!this.launchData && (!this.launchUrl || this.launchUrl === 'about:blank'))
            return nothing;

        return html`
            <paper-dialog
                id="elearning_dialog"
                class=${classMap({
                    transparent: this.transparent,
                    'new-window': this.launchType === launchTypes.LAUNCHTYPE_NEWWINDOW,
                })}
                ?opened=${this.opened}
                ?autoclose=${this.autoClose}
                modal>
                ${choose(this.playerType, [
                        [0, this._renderIFrame],
                        [1, this._renderOsPlayer],
                        [2, this._renderScormPlayer],
                    ],
                    this._renderIFrame,
                )}
                <div
                    class="close"
                    @click=${this._closeDialog}
                    ?hidden=${this.autoClose}>
                    <sdb-content-icon icon="fal fa-xmark"></sdb-content-icon>
                    <paper-ripple></paper-ripple>
                </div>
            </paper-dialog>
        `;
    }

    updated(changedProperties){
        if(changedProperties.has('opened') && this.opened) {
            import('@polymer/paper-dialog').catch(console.error);
        }
    }

    _renderIFrame = () => html`
        <iframe
            id="lti_player"
            src=${this.launchUrl}
            allowfullscreen="true"
            webkitallowfullscreen="true"
            mozallowfullscreen="true">
        </iframe>
    `;

    _renderOsPlayer = () => this.preview ?
        html`
            <sdb-os-player
                id="os_player"
                .user=${this.user}
                .settings=${userManager._settings}
                referenceId=${this.launchData?.referenceId}
                elearningId=${this.launchData?.elearningId}
                environment=${this.launchData?.environment}
                @playerExit=${this._closeDialog}
                browse>
            </sdb-os-player>
        ` : html`
            <sdb-os-player
                id="os_player"
                .user=${this.user}
                .settings=${userManager._settings}
                referenceId=${this.launchData?.referenceId}
                elearningId=${this.launchData?.elearningId}
                callback="composition"
                environment=${this.launchData?.environment}
                @playerExit=${this._closeDialog}>
            </sdb-os-player>
        `;


    _renderScormPlayer = () => html`
        <sdb-scorm-player
            id="scorm_player"
            packageId=${this.launchData?.packageId}
            sessionId=${this.launchData?.referenceId}
            launchType=${this.launchType}
            @finish=${this._closeDialog}>
        </sdb-scorm-player>
    `;

    _closeDialog = () => {
        if (!this.opened) {
            return;
        }

        this.closeAndNotifyElearning();
        this.reloadCompositionState();
    }
}
