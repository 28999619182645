import { translate } from './translate.js';

export const getIdfromTargetId = targetId => `COMP_${targetId.replace(/-/g, "")}`;

export const getIcon = (type) => {
    switch (type) {
        case 'movie': return 'fal fa-video';
        case 'audio': return 'fal fa-volume';
        case 'animation': return 'fal fa-images';
        case 'infographic': return 'fal fa-image';
        case 'exercise': return 'fal fa-check-to-slot';
        case 'microcourse': return 'fal fa-box-open';
        case 'text': return 'fal fa-file-lines';
        case 'test': return 'fal fa-graduation-cap';
        case 'pretest': return 'fal fa-square-check';
        case 'elearning': return 'fal fa-boxes-stacked';
        default: return 'fal fa-boxes-stacked';
    }
};

export const getDuration = (durationInSeconds) => {
    const minutes = toMinutes(durationInSeconds);
    return minutes === 1
        ? `${minutes} ${translate('studyMinute')}`
        : `${minutes} ${translate('studyMinutes')}`;
}

export const getDurationMinMax = (min, max) => {
    return `${toMinutes(min)} - ${toMinutes(max)} ${translate('studyMinutes')}`;
}

export const toMinutes = (seconds) => Math.round(seconds / 60);

export const getMessageForReduxError = (error) => {
    if (error instanceof ProgressEvent) {

        // ['composition', 'compositionState'].forEach(action => {
        //     if (state && state[action] && state[action].error) {
        //         state[action].error.then((e) => {
        //             console.error(e);
        //         })
        //     }
        // });

        return 'An error occurred getting data';
    }

    if (error instanceof String) {
        return error;
    }

    return 'An unknown error occurred.';
};

