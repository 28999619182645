export default {
    development: {
        compositionEndpoint: "https://compositionapi.test-sdbleerplatform.nl/api/1.0/composition/",
        draftEndpoint: "https://compositionapi.test-sdbleerplatform.nl/api/1.0/draft/",
        compositionStateEndpoint: "https://compositionapi.test-sdbleerplatform.nl/api/1.0/state/",
        compositionResetEndpoint: "https://compositionapi.test-sdbleerplatform.nl/api/1.0/reset",
        elearningEndpoint: "https://compositionapi.test-sdbleerplatform.nl/api/1.0/elearning/",
        imageEndpoint: "https://compositionapi.test-sdbleerplatform.nl/api/1.0/image/",
        scormEndpoint: 'https://compositionapi.test-sdbleerplatform.nl/api/1.0/scorm12/',
        signalRUrl: "https://compositionapi.test-sdbleerplatform.nl/statehub",
        microlearningGetStartUrlEndpoint: "https://lticonsumer.test-sdbleerplatform.nl/api/1.0/tcgStartUrl",
        sdbIdentityUrl: "test-sdbidentity.nl",
    },
    production: {
        compositionEndpoint: "https://compositionapi.sdbleerplatform.nl/api/1.0/composition/",
        draftEndpoint: "https://compositionapi.sdbleerplatform.nl/api/1.0/draft/",
        compositionStateEndpoint: "https://compositionapi.sdbleerplatform.nl/api/1.0/state/",
        compositionResetEndpoint: "https://compositionapi.sdbleerplatform.nl/api/1.0/reset",
        elearningEndpoint: "https://compositionapi.sdbleerplatform.nl/api/1.0/elearning/",
        imageEndpoint: "https://compositionapi.sdbleerplatform.nl/api/1.0/image/",
        scormEndpoint: 'https://compositionapi.sdbleerplatform.nl/api/1.0/scorm12/',
        signalRUrl: "https://compositionapi.sdbleerplatform.nl/statehub",
        microlearningGetStartUrlEndpoint: "https://lticonsumer.sdbleerplatform.nl/api/1.0/tcgStartUrl",
        sdbIdentityUrl: "sdbidentity.nl",
    },
}
