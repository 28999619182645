import { LitElement, html, css } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import connect from 'wc-context-redux/connect';
import { initialize, unload } from '../../store/scorm/scorm.js';
import { translate } from '../../helpers/translate.js';
import { launchTypes } from '../../store/elearning/helpers.js';
import '@polymer/paper-toast/paper-toast.js';
import '../shared/sdb-spinner.js'

const mapStateToProps = state => ({
    initialized : state.scorm.initialized,
    finished : state.scorm.finished,
    enabled: state.scorm.enabled,
    scormUrl: state.scorm.url,
});

const mapDispatchToProps = dispatch => ({
    initializeScorm: (packageId, sessionId) => dispatch(initialize({ packageId, sessionId })),
    unloadScorm: () => dispatch(unload()),
});

@customElement('sdb-scorm-player')
class SdbScormPlayer extends connect(mapStateToProps, mapDispatchToProps)(LitElement) {

    @property({ type: String })
    accessor sessionId = null;

    @property({ type: String })
    accessor packageId = null;

    @property({ type: Number })
    accessor launchType = launchTypes.LAUNCHTYPE_REGULAR;

    @state()
    accessor initialized = false;

    @state()
    accessor finished = false;

    @state()
    accessor scormUrl = null;

    get scormWindowIsOpen() {
        return this.scormWindow && !this.scormWindow.closed;
    }

    constructor() {
        super();
        this.scormWindow = null;
    }

    static get styles() {
        return css`
            .scorm-frame {
                width: 100%;
                height: 100%;
                border: none;
                margin: 0;
                padding: 0;
                background: #f7f7f7;
            }

            .newwindow {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .container {
                width: 70%;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: space-between;
            }

            #start_button {
                margin-top: 20px;
            }
        `;
    }

    render() {
        if (!this.initialized) {
            return html`<div class="content"><sdb-spinner></sdb-spinner></div>`;
        }

        if (!this.enabled) {
            return html`
                <div class="content">
                    <sdb-empty-view label=${translate('scormPackageNotEnabled')}></sdb-empty-view>
                </div>
            `;
        }

        if (this.launchType === launchTypes.LAUNCHTYPE_NEWWINDOW) {
            if (!this.scormWindowIsOpen) {
                this._openNewWindow();
            }

            return html`
                <div class="newwindow">
                    <div class="container">
                        <div class="message">
                            ${translate(this.scormWindowIsOpen
                                ? 'newWindowOpenedMessage'
                                : 'newWindowMessage')
                            }
                        </div>
                        <sdb-content-button
                            id="start_button"
                            label=${translate('startInNewWIndow')}
                            primary
                            ?hidden=${this.scormWindowIsOpen}
                            @click=${this._openNewWindow}>
                        </sdb-content-button>
                    </div>
                </div>
            `;
        }

        return html`
            <iframe
                class="scorm-frame"
                src=${this.scormUrl}
                allowfullscreen="true"
                webkitallowfullscreen="true"
                mozallowfullscreen="true">
            </iframe>
        `;
    }

    updated(changes) {
        if (changes.has('packageId') || changes.has('sessionId')) {
            if (this.scormWindowIsOpen) {
                this.scormWindow.close();
            }

            if (!this.packageId && !this.finished) {
                this.unloadScorm(); // if close was triggered outside of scormplayer
            }

            if (this.packageId && this.sessionId) {
                this.initializeScorm(this.packageId, this.sessionId);
            }
        }

        if (changes.has('finished') && this.finished) {
            if (this.scormWindow && !this.scormWindow.closed) {
                this.scormWindow.close();
            }

            this.dispatchEvent(new CustomEvent('finish', {
                composed: true,
                bubble: true,
            }));
        }
    }

    _openNewWindow() {
        if (this.scormWindowIsOpen) {
            return;
        }

        this.scormWindow = window.open(this.scormUrl, 'scormWindow');
    }
}
