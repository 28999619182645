import { store } from '../store/store.js';

export const getImageUrl = (documentId, image) => {
    if (!image) {
        return false;
    }

    if (image.indexOf('http') === 0) {
        return image;
    }

    const settings = store.getState().env.settings;
    return `${settings.imageEndpoint}${documentId}/${image}`;
}
