import { createSlice } from '@reduxjs/toolkit';
import { userManager, ensureUserManagerSettings } from './userManager.js';

export const authenticate = ({ matchingIdString }) => async (dispatch, getState) => {
    const { oidc } = getState();

    if (oidc.user) {
        if (matchingIdString && oidc.user.profile.sub !== window.atob(matchingIdString)) {
            await dispatch(signout());
            await dispatch(redirecting());
        } else {
            await dispatch(signedIn({ user: oidc.user }));
        }
    } else {
        dispatch(signin());
        dispatch(redirecting());
    }
};

export const signin = () => async () => {
    await ensureUserManagerSettings();

    userManager.signinRedirect({
        state: {
            url: window.location.pathname + window.location.search,
        },
    })
};

export const signedInExistingUser = ({ user }) => async (dispatch) => {
    await dispatch(signedIn({ user }));
};

export const signout = () => async dispatch => {
    await ensureUserManagerSettings();

    userManager.signoutRedirect({
        state: {
            url: window.location.pathname + window.location.search,
        }
    });
};

export const handleSilentRenewCallback = () => async dispatch => {
    await ensureUserManagerSettings();

    userManager.signinSilentCallback()
        .then(result => {
            console.log(result);
        })
        .catch(err => {
            console.error(err);
            dispatch({
                type: AUTHENTICATION_ERROR
            });
        });
};

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        redirecting: false,
        signedIn: false,
        user: null,
    },
    reducers: {
        redirecting: (state) => {
            state.redirecting = true;
            state.signedIn = false;
        },
        signedIn: (state, action) => {
            const { payload } = action;

            state.redirecting = false;
            state.signedIn = true;
            state.user = payload.user;
        },
        signedOut: (state) => {
            state.redirecting = false;
            state.signedIn = false;
            state.user = null;
        },
        signInError: (state) => {
            state.redirecting = false;
            state.signedIn = false;
            state.user = null;
            state.error = true;
        },
    },
});

export const { redirecting, signedIn, signedOut, signInError } = authSlice.actions;

export default authSlice.reducer;
