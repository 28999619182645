import { configureStore } from '@reduxjs/toolkit'
import {
    createOidcMiddleware,
    loadUser,
    reducer as oidc
} from '@competencegroup/redux-oidc-es6';
import { listenerMiddleware } from './listenerMiddleware.js';
import { userManager } from "./auth/userManager.js";
import composition from './composition/composition.js';
import elearning from './elearning/elearning.js';
import scorm from './scorm/scorm.js';
import signalR from './signalR/signalR.js';
import auth from './auth/auth.js';
import env from './env/env.js';
import notify from './notify/notify.js';

export const store = configureStore({
    reducer: {
        oidc: oidc,
        composition: composition,
        elearning: elearning,
        scorm: scorm,
        signalR: signalR,
        auth: auth,
        env: env,
        notify: notify,
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        thunk: true,
        devTools: true,
        serializableCheck: {
            ignoredTypes: ['redux-oidc/USER_FOUND'],
            ignoredPaths: ['oidc.user']
        }
    }).prepend(
        listenerMiddleware.middleware,
    ).concat([
        createOidcMiddleware(userManager),
    ]),
});

loadUser(store, userManager);
