import { LitElement, html, css, nothing } from 'lit';
import { customElement, property, state, query } from 'lit/decorators.js';
import connect from 'wc-context-redux/connect';
import { load, loadPreview } from '../../store/composition/composition.js';
import { notifyDone } from '../../store/notify/notify.js';
import '@polymer/paper-toast/paper-toast.js';
import '../shared/sdb-overlay-element.js';
import '../composition/sdb-composition-item.js';
import '../composition/sdb-composition-compound-item.js';
import '../composition/sdb-composition-compound-test-item.js';
import '../composition/sdb-composition-status.js';

const mapStateToProps = state => ({
    composition: state.composition.data,
    isLoading: state.composition.isLoading || !state.composition.data,
    message: state.notify.text,
});

const mapDispatchToProps = dispatch => ({
    load: (compositionId, sessionId) => dispatch(load({ compositionId, sessionId })),
    loadPreview: (compositionId, type) => dispatch(loadPreview({ compositionId, type })),
    notifyDone: () => dispatch(notifyDone()),
});

@customElement('sdb-composition-player')
class SdbCompositionPlayer extends connect(mapStateToProps, mapDispatchToProps)(LitElement) {

    @property({ type: String })
    accessor compositionId;

    @property({ type: String })
    accessor sessionId;

    @property({ type: String })
    accessor type;

    @property({ type: Boolean })
    accessor preview;

    @property({ type: Boolean })
    accessor hideCompositionStatus;

    @property({ type: Boolean })
    accessor browse;

    @state()
    accessor composition;

    @state()
    accessor isLoading;

    @state()
    accessor message;

    @query('#player_toast')
    accessor toast;

    set message(val) {
        if (val) {
            this._showMessage(val);
        }
    }

    static get styles() {
        return css`
            .player {
                max-width: 1100px;
                margin-left: auto;
                margin-right: auto;
            }

            .composition-status {
                display: flex;
            }

            sdb-composition-status {
                width: 100%;
            }

            .composition-content {
                display: flex;
                flex-flow: row wrap;
            }
        `;
    }

    render() {
        if (!this.composition) {
            return html`<div></div>`;
        }

        return html`
            <div class="player">
            <div class="composition-status">
                ${!this.preview && !this.hideCompositionStatus ? html`
                    <sdb-composition-status
                        .composition=${this.composition}
                        noSettings="hideAll"
                        small>
                    </sdb-composition-status>
                ` : nothing}
            </div>
            <div class="composition-content">
                ${this.composition.items.map(this._renderItem)}
            </div>
            </div>
            <paper-toast
                id="player_toast"
                class="toast">
            </paper-toast>
        `;
    }

    firstUpdated() {
        if (this.preview) {
            this.loadPreview(this.compositionId, this.type);
            return;
        }

        this.load(this.compositionId, this.sessionId);
    }

    _renderItem(item) {
        switch (item.type) {
            case 'compound':
                return html`<sdb-composition-compound-item .item=${item}></sdb-composition-compound-item>`;
            case 'compound-test':
                return html`<sdb-composition-compound-test-item .item=${item}></sdb-composition-compound-test-item>`;
            default:
                return html`<sdb-composition-item .item=${item}></sdb-composition-item>`;
        }
    }

    _showMessage(text) {
        if (!this.toast)
            return;

        this.toast.text = text;
        this.toast.show();
        this.notifyDone();
    }
}
