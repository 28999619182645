import { createSlice, isRejected } from '@reduxjs/toolkit';
import { startListening } from '../listenerMiddleware.js';
import { translate } from '../../helpers/translate.js';

const notifySlice = createSlice({
    name: 'notify',
    initialState: {
        text: null,
    },
    reducers: {
        notify: (state, action) => {
            const { payload } = action;

            state.text = payload.text;
        },
        notifyDone: (state) => {
            state.text = null;
        },
    },
});

export const { notify, notifyDone } = notifySlice.actions;

export default notifySlice.reducer;

startListening({
    matcher: isRejected,
    effect: async (action, { dispatch }) => {
        dispatch(notify({ text: `${action.type}: ${action.error.message}`}));
    },
});
