import { createAsyncThunk, createSlice, isPending, isRejected } from '@reduxjs/toolkit';
import { startListening } from '../listenerMiddleware.js';
import { httpGet, httpPost } from '../../helpers/http.js';
import ScormAPI12Adapter from '@competencegroup/tcg.scorm/dist/ScormAPI12Adapter';

export const initialize = createAsyncThunk(
    'scorm/initialize',
    async({ packageId, sessionId }, { getState, dispatch }) => {
        const state = getState();
        const { settings } = state.env;
        const { user } = state.auth;

        const scorm = await httpGet(`${settings.scormEndpoint}${packageId}/${sessionId}`, user);

        const scormApi = new ScormAPI12Adapter({
            debug: false,
            defaults: [
                { name: 'studentId', value: user.profile.idString },
                { name: 'studentName', value: user.profile.name },
                { name: 'lessonStatus', value: scorm.result.cmiLessonStatus },
                { name: 'lessonMode', value: scorm.result.cmiLessonMode },
                { name: 'lessonLocation', value: scorm.result.cmiLessonLocation },
                { name: 'entry', value: scorm.result.cmiEntry },
                { name: 'suspendData', value: scorm.result.cmiSuspendData },
                { name: 'launchData', value: scorm.startData || '' },
            ],
        });

        scormApi.onLmsCommit.on(() => {
            dispatch(updateResult());
        });

        scormApi.onLmsFinish.on(() => {
            dispatch(updateResult());
            dispatch(finish());
        });

        window.API = scormApi;

        // just strip protocol from startUrl and add to our scorm rewrite path
        const scormUrl = `scorm-az/${scorm.startUrl.replace(/(^\w+:|^)\/\//, '')}`;

        return {
            enabled: scorm.enabled,
            targetId: state.elearning.launchId, // use here cause we need it in our scorm context
            scormUrl,
            scormResult : scorm.result,
        };
    },
);

export const saveResult = createAsyncThunk(
    'scorm/save',
    async(_, { getState }) => {
        const { scorm: state, env, auth } = getState();

        await httpPost(
            env.settings.scormEndpoint + state.targetId,
            auth.user,
            state.result,
        );

        return {};
    },
    {
        condition: (_, { getState }) => {
            const { scorm: state } = getState();

            if (!state.result || !state.targetId) {
                return false;
            }
        },
    },
);

const scormSlice = createSlice({
    name: 'scorm',
    initialState: {
        url: null,
        result: null,
        initialized: false,
        finished: false,
        log: [],
    },
    reducers: {
        updateResult: (state) => {
            state.result.cmiElements = window.API.scoData.cmiElements.map((el) => ({
                name: el.name,
                value: new String(el.value),
            }));
        },
        unload: (state) => { // triggered outside sco
            state.url = 'about:blank';
        },
        finish: (state) => { // triggered from sco
            state.finished =true;
            state.closed = true;
            state.url = 'about:blank';

            window.API = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(initialize.pending, (state) => {
                state.initialized = false;
                state.status = 'initializing';
            })
            .addCase(initialize.fulfilled, (state, action) => {
                const { payload } = action;

                state.status = 'success';
                state.initialized = true;
                state.finished = false;
                state.enabled = payload.enabled;
                state.targetId = payload.targetId;
                state.url = payload.scormUrl;
                state.result = payload.scormResult;
            })
            .addCase(saveResult.pending, (state) => {
                state.status = 'saving';
            })
            .addCase(saveResult.fulfilled, (state) => {
                state.status = 'success';
                if (state.finished) {
                    state.result = null;
                }
            })
            .addMatcher(isRejected(initialize, saveResult), (state, action) => {
                state.status = 'failed';
                state.error = action.error;
            });
    },
});

export const { updateResult, unload, finish } = scormSlice.actions;

export default scormSlice.reducer;

startListening({
    actionCreator: updateResult,
    effect: async (_, { dispatch, cancelActiveListeners, delay }) => {
        cancelActiveListeners();
        await delay(50);
        await dispatch(saveResult());
    },
});

startListening({
    actionCreator: unload,
    effect: async (_, { dispatch, getState, delay }) => {
        await delay(1000); // time to handle finishing by the sco

        const { scorm } = getState();
        if (!scorm.finished) { // if not finished, failsafe by imitating lms finish
            dispatch(updateResult());
            dispatch(finish());
        }
    },
});
