import { html, css, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';

@customElement('sdb-icon-pill')
class SdbIconPill extends LitElement {

    @property({ type: String})
    accessor icon = 'face-smile';

    @property({ type: String})
    accessor label = '';

    static get styles() {
        return css`
            :host {
                border-radius: 10px;
                padding: 3px 7px 3px 3px;
                background-color: rgba(255, 255, 255, .87);
                font-size: 11px;
                color: rgba(0,0,0, .7);
                text-transform: uppercase;
                display: flex;
                align-items: center;
            }

            sdb-content-icon {
                color: rgba(0,0,0, .87);
            }

            span.duration {
                margin-left: 5px;
            }
        `;
    }

    render() {
        return html`
            <sdb-content-icon icon="fal fa-${this.icon}" size="16"></sdb-content-icon>
            <span class="duration">${this.label}</span>
        `;
    }
}


