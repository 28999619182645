import { LitElement, html, css, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { translate } from '../../helpers/translate.js';
import { compositionTypes, completionTypes } from '../../store/composition/helpers.js';
import { toMinutes } from '../../helpers/helpers.js';
import '@sdblearning/sdb-learning-frontend';

@customElement('sdb-composition-status')
class SdbCompositionSttaus extends LitElement {

    @property({ type: Object })
    accessor composition = undefined;

    @property({type: Boolean})
    accessor light = false;

    @property({type: Boolean})
    accessor small = false;

    @property({type: Boolean})
    accessor compressed = false;

    @property({type: String})
    accessor noSettings = 'showStatus'; // showAll, showStatus. hideAll

    get completionType() {
        return this.composition.progressSettings?.completionType;
    }

    get completionDuration() {
        return this.composition.progressSettings?.minTotalDuration;
    }

    get hasNoSettings() {
        if (this.completionDuration)
            return false;

        return !this.completionType || this.completionType === completionTypes.NO_SETTINGS;
    }

    get icons() {
        return {
            started: 'fal fa-hourglass',
            incomplete: 'fal fa-hourglass-start',
            completed: 'fal fa-check',
            passed: 'fal fa-check',
            failed: 'fal fa-exclamation',
        };
    }

    get progress() {
        return this.composition.progress !== 'completed'
            ? this.composition.progress
            : !this.composition.success
                ? 'completed'
                : this.composition.success;
    }

    get timeProgress() {
        return Math.min(
            Math.round(100 * this.composition.totalTimeCompleted / this.completionDuration),
            100,
        );
    }

    get mandatoryProgress() {
        return Math.min(
            Math.round(100 * this.composition.totalMandatoryItemsCompleted / this.composition.totalMandatoryItems),
            100,
        );
    }

    static get styles() {
        return css`
            .light {
                color: #fff;
            }

            .status-container {
                display: flex;
                justify-content: left;
                margin-top: 20px;
            }

            :host([small]) .status-container {
                margin-top: 7px;
            }

            .status-container > div {
                display: flex;
                align-items: center;
            }

            .status-container sdb-content-icon {
                margin-right: 10px;
            }

            sdb-content-summaryitem:not(.light) sdb-content-icon {
                color: gray;
            }

            sdb-content-summaryitem:not(.light) sdb-content-icon.completed,
            sdb-content-summaryitem:not(.light) sdb-content-icon.completed.passed {
                color: green;
            }

            sdb-content-summaryitem:not(.light) sdb-content-icon.failed {
                color: red;
            }

            sdb-content-summaryitem.light {
                --text-light: var(--tcg-primary-color-ontertiary, #fff);
            }

            sdb-content-summaryitem:not(.light) {
                border-color: rgba(0, 0, 0, .2);
            }

            .secondHeader {
                margin-top: 10px;
                margin-bottom: 3px;
                white-space: nowrap;
            }

            sdb-content-summaryitem::part(headline) {
                white-space: nowrap;
            }
        `;
    }

    render() {
        if (!this.composition || this._hasNoSettingsMode('hideAll')) {
            return nothing;
        }

        return html`
            <sdb-content-summary ?noPadding=${this.small}>
                <sdb-content-summaryitem
                    slot="1"
                    class=${classMap({ light: this.light })}
                    icon="fal fa-badge"
                    headline=${translate('status')}>
                    <div class="status-container">
                        <div>
                            <sdb-content-icon
                                class=${this.progress}
                                icon=${this.icons[this.progress]}
                                size=${this.small ? '24' : '32'}>
                            </sdb-content-icon>
                            <div>${translate(`progress_${this.progress}`)}</div>
                        </div>
                    </div>
                </sdb-content-summaryitem>

                ${this._hasNoSettingsMode('showStatus') ? nothing
                    : this.composition.compositionType === compositionTypes.COMPOSITIONTYPE_SINGLEITEM ? html`
                        <sdb-content-summaryitem
                            slot="2"
                            class=${classMap({ light: this.light })}
                            icon="fal fa-circle-info"
                            headline=${translate('information')}>
                            <div class="status-container">
                                <div>
                                    <span>${translate('singleItemMessage')}</span>
                                </div>
                            </div>
                        </sdb-content-summaryitem>
                    ` : this.compressed ? html`
                        <sdb-content-summaryitem
                            slot="2"
                            class=${classMap({ light: this.light })}
                            icon="fal fa-seal-exclamation"
                            headline=${this._getMandatoryItemsHeadline()}>
                            <sdb-content-progressbar
                                progress=${this.mandatoryProgress}
                                value=${this.composition.totalMandatoryItemsCompleted}
                                totalvalue=${this.composition.totalMandatoryItems}
                                ?lightBar=${this.light}
                                smallBar>
                            </sdb-content-progressbar>
                            ${this.completionDuration ? html`
                                    <div class="secondHeader">${translate('mandatoryStudyMinutes')}</div>
                                    <sdb-content-progressbar
                                        progress=${this.timeProgress}
                                        value=${toMinutes(this.composition.totalTimeCompleted)}
                                        totalvalue=${toMinutes(this.completionDuration)}
                                        ?lightBar=${this.light}
                                        smallBar>
                                    </sdb-content-progressbar>
                                ` : nothing
                            }
                        </sdb-content-summaryitem>
                    ` : html`
                        <sdb-content-summaryitem
                            slot="2"
                            class=${classMap({ light: this.light })}
                            icon="fal fa-seal-exclamation"
                            headline=${this._getMandatoryItemsHeadline()}>
                            <sdb-content-progressbar
                                progress=${this.mandatoryProgress}
                                value=${this.composition.totalMandatoryItemsCompleted}
                                totalvalue=${this.composition.totalMandatoryItems}
                                ?lightBar=${this.light}
                                ?smallBar=${this.small}>
                            </sdb-content-progressbar>
                        </sdb-content-summaryitem>
                    `
                }

                ${this.completionDuration && !this.compressed ? html`
                        <sdb-content-summaryitem
                            slot="3"
                            class=${classMap({ light: this.light })}
                            icon="fal fa-clock"
                            headline="${translate('mandatoryStudyMinutes')}">
                            <sdb-content-progressbar
                                progress=${this.timeProgress}
                                value=${toMinutes(this.composition.totalTimeCompleted)}
                                totalvalue=${toMinutes(this.completionDuration)}
                                ?lightBar=${this.light}
                                ?smallBar=${this.small}>
                            </sdb-content-progressbar>
                        </sdb-content-summaryitem>
                    ` : nothing
                }
            </sdb-content-summary>
        `;
    }

    _getMandatoryItemsHeadline() {
        switch(this.completionType) {
            case completionTypes.REQUIRED_BLOCKS:
                return translate('mandatoryItems_marked');
            case completionTypes.ANY_BLOCK:
                return translate('mandatoryItems_any');
            case completionTypes.ALL_BLOCKS:
                return translate('mandatoryItems_all');
            case completionTypes.MIN_NUM_BLOCKS:
                return translate('mandatoryItems_all', { num: this.composition.progressSettings.minNumberItems});
            case completionTypes.NO_SETTINGS:
                return this.completionDuration
                    ? translate('mandatoryItems_durationOnly')
                    : translate('mandatoryItems_noSettings');
        }
    }

    _hasNoSettingsMode(mode) {
        return mode === this.noSettings && this.hasNoSettings;
    }
}
