import { LitElement, html, css, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { styleMap } from 'lit/directives/style-map.js';
import connect from 'wc-context-redux/connect';
import { load, loadPreview } from '../../store/composition/composition.js';
import '../buttons/sdb-composition-start-button.js';
import '../buttons/sdb-composition-reset-button.js';
import '../composition/sdb-composition-status.js';
import { compositionTypes } from '../../store/composition/helpers.js';

const mapStateToProps = state => ({
    composition: state.composition.data,
    isLoading: state.composition.isLoading || !state.composition.data,
});

const mapDispatchToProps = dispatch => ({
    load: (compositionId, sessionId) => dispatch(load({ compositionId, sessionId })),
    loadPreview: (compositionId, type) => dispatch(loadPreview({ compositionId, type })),
});

@customElement('sdb-composition-player-header')
class SdbCompositionPlayerHeader extends connect(mapStateToProps, mapDispatchToProps)(LitElement) {

    @property({ type: String })
    accessor compositionId = undefined;

    @property({ type: String })
    accessor sessionId = undefined;

    @property({ type: String })
    accessor type = undefined;

    @property({ type: Boolean })
    accessor preview = false;

    @property({ type: Boolean })
    accessor startDisabled = false;

    @property({ type: Boolean })
    accessor hideHeaderButtons;

    @property({ type: Boolean })
    accessor resetDisabled = false;

    @property({ type: Boolean })
    accessor resetHidden = false;

    @property({ type: Boolean })
    accessor light = false;

    @property({ type: Boolean })
    accessor small = false;

    @property({ type: Boolean })
    accessor compressed = false;

    @state()
    accessor composition = undefined;

    @state()
    accessor isLoading = false;

    static get styles() {
        return css`
            :host {
            }

            .buttons {
                display: flex;
                justify-content: right;
                padding-right: 35px;
                padding-bottom: 35px;
            }

            :host([small]) .buttons {
                padding-right: 0;
                padding-bottom: 0;
            }

            sdb-composition-reset-button {
                margin-left:10px;
            }

            sdb-composition-reset-button[hidden] {
                display: none;
            }
        `;
    }

    render() {
        if (!this.composition) {
            return nothing;
        }

        return html`
            <sdb-composition-status
                .composition=${this.composition}
                ?light=${this.light}
                ?small=${this.small}
                ?compressed=${this.compressed}>
            </sdb-composition-status>

            ${!this.hideHeaderButtons ? html`
                <div class="buttons">
                    <sdb-composition-start-button
                        ?disabled=${this.startDisabled}
                        ?light=${this.light}>
                    </sdb-composition-start-button>
                    <sdb-composition-reset-button
                        ?disabled=${this.resetDisabled}
                        ?hidden=${this.resetHidden}
                        ?light=${this.light}>
                    </sdb-composition-reset-button>
                </div>`: nothing}

        `;
    }

    firstUpdated() {
        if (this.preview) {
            this.loadPreview(this.compositionId, this.type);
            return;
        }

        this.load(this.compositionId, this.sessionId);
    }
    updated(){
       if(this.composition){
        const isSingleItem = this.composition.compositionType === compositionTypes.COMPOSITIONTYPE_SINGLEITEM;
        this.dispatchEvent(new CustomEvent('is-single-item',{
            bubbles: true,
            composed: true,
            detail: isSingleItem

        }));
       }
    }
}
