import {
    createAsyncThunk,
    createSlice,
    isRejected
} from '@reduxjs/toolkit';
import { httpGet, httpPost } from '../../helpers/http.js';
import { launchTypes } from './helpers.js';

export const launchElearning = createAsyncThunk(
    'elearning/launch',
    async (args, { getState }) => {
        const { env, auth } = getState();
        const { playerType, launchId, launchType, progress } = args.item;
        const { user } = auth;
        const isNewOrResetted = !progress;

        let url = null,
            launchData = null,
            playerLaunchType = launchTypes.LAUNCHTYPE_REGULAR;

        if (playerType === undefined || playerType === null || playerType === 'LTI1_TCG') {
            // fallback for legacy tcg lti items
            const ltiData = await httpPost(env.settings.microlearningGetStartUrlEndpoint, user, {
                uniqueId: launchId,
                launchPresentationReturnUrl: getReturnUrl(launchType, true),
                reset: isNewOrResetted, // tcgStartUrl only
                displayName: user.profile.name,
                email: user.profile.email,
            });
            url = ltiData.url;
        } else {
            const command = isNewOrResetted ? 'reset-start' : 'start'
            const data = await httpGet(`${env.settings.elearningEndpoint}${launchId}/${command}`, user);

            playerLaunchType = data.launchType || launchTypes.LAUNCHTYPE_REGULAR;

            switch (playerType) { // fix case types when we're sending enums as strings
                case 0: // 'lti1':
                    const ltiData = await httpPost(data.startUrl, user, {
                        uniqueId: launchId,
                        launchPresentationReturnUrl: getReturnUrl(launchType, false),
                        instanceId: data.session.referenceId,
                        idString: user.profile.sub, // startUrl only
                        displayName: user.profile.name,
                        email: user.profile.email,
                    });
                    url = ltiData.url;
                    break;
                case 1: // 'onlineStudio':
                case 2: // 'scorm12':
                    launchData = {
                        ...data.startData,
                        referenceId: data.session.referenceId
                    };
                    break;
                case 3: // 'blackBox':
                default:
                    url = data.startUrl;
                    launchData = data.startData;
                    break;
            }
        }

        switch (launchType) {
            case 'popup':
                return {
                    playerType: playerType,
                    launchId: launchId,
                    launchType: playerLaunchType,
                    launchData: launchData,
                    url: url,
                    popupCanClose: true,
                    popup: true,
                };

            case 'popup-close':
                return {
                    playerType: playerType,
                    launchId: launchId,
                    launchType: playerLaunchType,
                    launchData: launchData,
                    url: url,
                    popupCanClose: false,
                    popup: true,
                };

            case 'window':
                window.open(url, '_blank');
                return {
                    launchId: launchId,
                    launchType: playerLaunchType,
                    popup: false,
                };

            case 'self':
            default:
                window.location.href = url;
                return {
                    launchId: launchId,
                    url: url,
                    popup: false,
                };
        }
    },
);

export const closeElearning = createAsyncThunk(
    'elearning/close',
    async (args, { getState, dispatch }) => {
        const { command } = args || {};
        const { elearning, env, auth } = getState();
        const { launchId, playerType } = elearning;

        let data = {};
        switch (command) {
            case 'notify':
                data = await httpPost(`${env.settings.elearningEndpoint}session/${launchId}/end`, auth.user);
                break;
        }

        return {
            launchType: 'popup',
            ...data,
        };
    },
);

const getReturnUrl = (launchType, legacy) => {
    const port = window.location.port ? `:${window.location.port}` : '';
    const baseUrl = `${window.location.protocol}//${window.location.hostname}${port}`;

    switch (launchType) {
        case 'self': return window.location.href;
        case 'popup': return legacy
            ? `${baseUrl}/elearning-close-dialog`
            : `${baseUrl}/elearning-close-dialog-and-notify`;
        default: return '';
    }
};

const elearningSlice = createSlice({
    name: 'elearning',
    initialState: {
        status: 'idle',
        error: null
    },
    extraReducers: (builder) => {
        builder
            .addCase(launchElearning.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(launchElearning.fulfilled, (state, action) => {
                const { playerType, launchId, launchType, launchData, url, popup, popupCanClose } = action.payload;

                state.status = 'success';
                state.playerType = playerType;
                state.launchId = launchId;
                state.launchType = launchType;
                state.launchData = launchData;
                state.launchUrl = url;
                state.popupOpen = popup ? true : undefined;
                state.autoClose = popup ? popupCanClose : undefined;
            })
            .addCase(closeElearning.pending, (state) => {
                state.status = 'closing';
            })
            .addCase(closeElearning.fulfilled, (state) => {
                state.status = 'success';
                state.launchId = null;
                state.launchType = launchTypes.LAUNCHTYPE_REGULAR;
                state.launchData = null;
                state.launchUrl = null;
                state.popupOpen = false;
            })
            .addMatcher(isRejected(launchElearning, closeElearning), (state, action) => {
                state.status = 'failed';
                state.error = action.error;
            });
    },
});

export default elearningSlice.reducer;
